.customCard, .customCardLink{
    text-align: left;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 16px 16px 0px;
    border-left: 5px solid #989898; 
    border-left-color: #989898;
}

.customCardLink:hover{
    background-color: #eee !important;;
}

.customCard div{
    padding-bottom: 5px;
    padding-top: 5px;
}


/*----------------------------------------------------------------*/

/*----------------------------------------------------------------*/
.title{
    margin-top: 5px;
    margin-bottom: 5px;
    color: #6c757d;
    font-weight: bold;
}

.cardLink{
    text-decoration: none;
}
.cardLink:hover{
    background-color: #989898;
}