.btnLoadMore{
    color: white;
    background-color: #0C9BED;
    border: 2px solid #0C9BED;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
}
.btnLoadMore:hover{
    color: #0C9BED;
    background-color: white;
}