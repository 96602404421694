.languageButton{
    background-color: white;
    color: #243F30;
    border: 0px;
    font-weight: bold;
    font-size: small;
  }
  .languageButton:active,
  .languageButton:hover{
    background-color: white;
    color: #0c9bed;
    border: 0px;
  }
  
  .languageMenu{
    width: 50px;
    min-width: fit-content;
    font-size: small;
  }