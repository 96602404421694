.modalWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all 1s;
  }


.btnSharePoint{
    position: fixed;
    cursor: pointer;
    opacity: 1;
    z-index: 8999;
    top: 10px;
    right: 110px;
    color: #494d51;
  
    border: 1px solid #adadad;

    background-color: #adadad;
    text-decoration: none;

    font-weight: bold;
    border-radius: 50%;
}
.btnSharePoint:hover{

    color: #494d51;
    border: 1px solid #fff;
    background-color: #fff;
  
}
.iconSharePoint{
  width: 35px;
  border-radius: 50%;
}



.btnClose {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 8999;
    top: 10px;
    right: 30px;
    font-size: xx-large;
  }
.btnClose:hover, .btnDownload:hover {
    opacity: 1;
}

.btnDownload {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 8999;
    top: 10px;
    right: 70px;
    font-size: xx-large;
  }

  
  .iframe{
    background: whitesmoke; 
  }
  .iframe > html > body{
    color:white

  }

  .divNoPreview{
    background: whitesmoke; 
    text-align: center;
    font-size: large;
    font-weight: bold;
  }