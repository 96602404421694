.customCard{
    text-align: left;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 16px 16px 0px;
    border-left: 5px solid #989898; 
    border-left-color: #989898;
    font-size: x-small;
}

.badge{
    border:0px;
    color: white;
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #989898;
    font-size: xx-small;
}
.title{
 
    font-weight: bold;
    font-size: x-small;
}