.badge{
    display: inline-block;
    border:0px;
    color: white;
    border-radius: 10px;
    text-align: center;
    background-color: #989898;
    width: 50px;
    font-size: small;
}

/* condition for screen size minimum of 800px */
@media (min-width:800px) {

    /* your conditional / responsive CSS inside this condition */
  
    .large{
        width: 80px;
    }
  
  }

