.header {
	display: flex;
	justify-content: right;
  padding:5px 25px 5px 25px;
 /* padding-right: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000));
  padding-left: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000));*/
  background-color: white;
  color: #00af43;
  height: 70px;
  border-bottom: 2px solid #ddd;
  
}



.headerLeft{
  display: flex;
  justify-content: left;
  height: 70px;
  padding-top: 10px;
  padding-left: 20px;
}

.logoLink{
  max-height: 40px;
}

.logoLink:hover{
  cursor: pointer;
}

.headerRight{
  display: flex;
  justify-content: right;
  padding-right: 10px;
}







