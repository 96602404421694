.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
  }
  .spinner:after {
    content: ' ';
    display: block;
    width: 34px;
    height: 34px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #0C9BED;
    border-color: #0C9BED transparent #0C9BED transparent;
    animation: spinner 1.2s linear infinite;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
/*********************************/
.spinnerSmall {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.spinnerSmall:after {
  content: ' ';
  display: block;
  width: 24px;
  height: 24px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #0C9BED;
  border-color: #0C9BED transparent #0C9BED transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinnerSmall {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
