
.userProfileButton{
  background-color: transparent;
  border: 0px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.userProfileButton:hover{
  background-color: transparent;
  border: 0px;
}

.userIconButton{
  border:2px solid #0c9bed;
  color: #0c9bed;
  border-radius: 50%;
  font-weight: bold;
  font-size: small;
  padding: 7px 9px 7px 9px;
  margin-right: 10px;
}

.userIconButton:hover{
  background-color: #0c9bed;
  border:2px solid #0c9bed;
  color: white;
}


.userImgButton{
  width:30px;
  border-radius: 50%;
  background-color: #0c9bed;
  border:2px solid #0c9bed;
}
.userImgButton:hover{
  background-color: white;
  border:2px solid white;
}




  .userProfileMenu{
    position: absolute;
    transform: translate3d(-190px, 0px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
  }
  .userProfile{
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .signOutButton{
    font-weight: bold;
  }

  .divUserName{
    display: block;
    height: 50px;
  }

  .userName{
    font-size: large;
    font-weight: bold;
    vertical-align: middle;
 
  }
  .userImg{
    width:50px;
    border-radius: 50%;
    padding-right: 5px;
  }

  .userIcon{
    border:2px solid #6c757d ;
    border-radius: 50%;
    color: #6c757d ;

    padding: 5px 8px 5px 8px;
    margin-right: 3px;

  }