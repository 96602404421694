.customCard{
    text-align: left;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
}
.title{
    font-style: italic;
    font-weight: bold;
}

.meetingLink{
    font-weight: bold;
    text-decoration: underline;
    padding-left: 5px;
}
.meetingLink:hover{
    text-decoration: none;
}

.agendapointLink{
    color: black;
    border: 0px;
    text-align: left;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 5px;
}
.agendapointLink:hover{
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.previewImg{
    width: 100px;
    border: 1px solid #ddd;
    padding: 3px;
}
.btnPreview{
    border:2px solid #0c9bed;

}
.btnPreview:hover{
    border:2px solid #ddd;
}

.orderBadgeSmall{
    border-radius: 50%;
    background-color: #32BA62;
    padding-left: 7px;
    padding-right:7px;
    padding-top: 2px;
    padding-bottom: 3px;
    color: white;
    font-size: small;
    font-weight: bold;
}

.orderBadgeLarge{
    border-radius: 50%;
    background-color: #32BA62;
    padding-left: 7px;
    padding-right:7px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    font-size: small;
    font-weight: bold;
}

.divSummary{
    padding-bottom: 15px;
}