.navLink, .navLink:hover{
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: large;

    text-align: center;
    background-color: white;
    color: #0C9BED;
    border: 1px solid #0C9BED;
    padding-left:30px;
    padding-right: 30px;
    padding-top:5px;
    padding-bottom:5px;
}

.navLinkCalendar{
    border-radius: 0px 8px 8px 0px;
}
.navLinkOverview{
    border-radius: 8px 0px 0px 8px;
}

.activeNavLink,.activeNavLink:hover{
    background-color: #0C9BED;
    color: white;
}

.typesCard{
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 20px;
}