.tab{
    border:1px solid;
    color: black;
    border-radius: 0px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #F0F0F0;;
    border-color: #989898;
    border-radius: 4px 4px 0px 0px;
}

.tabActive{
    color: white;
    background-color: #0c9bed;
    border-color: #0c9bed;
    border-radius: 8px 8px 0px 0px;
    padding-top: 5px;
}

.tabBody{
    border:1px solid #989898;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 16px 16px 16px;
    padding: 10px;
}
