.orderBadgeSmall{
    border-radius: 50%;
    background-color: #32BA62;
    padding-left: 7px;
    padding-right:7px;
    padding-top: 2px;
    padding-bottom: 3px;
    color: white;
    font-size: small;
    font-weight: bold;
}

.orderBadgeLarge{
    border-radius: 50%;
    background-color: #32BA62;
    padding-left: 7px;
    padding-right:7px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    font-size: small;
    font-weight: bold;
}
.link{
    background-color: white;
    border: 0px;
    text-align: left;
    font-weight: bold;
    text-decoration: underline;
}
.link:hover{
    cursor: pointer;
    text-decoration: none;
}


