.image{
    width: 100%;
}

.signInButton{
    background: #0C9BED;
    border: 1px solid #0C9BED;
    border-radius: 21px;
    padding: 5px 50px;
    color: white;
    font-size: small;
    font-weight: bold;
}

.rightColumn{
    text-align: center;
    vertical-align: middle;
}