.customCard{
    text-align: left;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius:16px;
}

.badge{
    border:0px;
    color: white;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #32BA62;
    font-size: small;
}

.title{
    margin-top: 15px;
    margin-bottom: 5px;
    color: black;
    font-weight: bold;
    text-decoration: underline;
}