.infoButton{
    background-color: white;
    color: #ddd;
    border:1px solid transparent;
    padding-left: 5px;
    border-radius: 50%;
    margin: 0px;
}

.infoButton:hover{
    color:#0c9bed;
}

.infoTooltip{
    padding-left: 5px;
}

