.link{
    background-color: white;
    border: 0px;
    text-align: left;
    font-weight: bold;
    text-decoration: underline;
}
.link:hover{
    cursor: pointer;
    text-decoration: none;
}

.statusBadge{
    border-radius: 8px;
    border: 2px solid white;
    padding-left: 7px;
    padding-right:7px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: small;
    font-weight: bold;
}
.statusApproved{
    border-color: #32BA62;
    color: #32BA62;
}

.statusRejected{
    border-color: #ba324f;
    color: #ba324f;
}

.statusPending{
    border-color: #0C9BED;;
    color: #0C9BED;;
}
.statusDraft{
    border-color: #6c757d;
    color: #6c757d;
}
.folderIcon{
    color: #6c757d;
    font-size: x-large;
}

