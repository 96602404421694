.App {
  text-align: center;
}

.navbarStyle {
  padding: .5rem 1rem !important
}

ul{
  list-style-type: none;
  padding-top: 10px;
  text-align: left;
  padding-left: 0px;
}

li{
  padding-top: 10px;
  padding-bottom: 10px;
}


.row{
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
}

img.icon{
  width: 17px;

}

.fontAwesomeIcon{
/*padding-left: 5px;*/
padding-right: 5px;
}

.circleButton{
  background-color: white;
  border:2px solid #0c9bed;
  color: #0c9bed;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  font-size: small;
  padding: 3px;
  margin-left: 10px;
}

.circleButton:hover{
  background-color: #0c9bed;
  border:2px solid #0c9bed;
  color: white;
}

.searchInput{
  background: #F9F9F9;
  /* Form/default stroke */

  border: 1px solid #E0E0E0;
  border-radius: 4px;
  width: 300px;
  color: #E0E0E0;
}
/* SEARCH FORM - > in header */
form.nosubmit {
  border: none;
  padding: 0;
  margin-right: 50px;
}

input.nosubmit {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  width: 260px;
  display: block;
  padding: 3px 4px 3px 40px;
  background: #F9F9F9 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}
input.nosubmit:focus{
  border: none;

}

input[type='checkbox'] {
  accent-color: #0c9bed;/* #4BE48C; --green*/
  color: white;
  background-color: white;
  
}

button[type="submit"]:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
}
/* END --- SEARCH FORM - > in header */

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}


.centered {
  /*margin: 3rem auto;*/
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
/***********************************/
.backButton{
  color:#0c9bed;
  font-weight: bold;
}
.backButton:hover{
  text-decoration: underline;
  cursor: pointer;
}
/****************************************/
.gridHeaderRow{
  font-weight: bold;
  border-bottom: 1px solid black;
}
.gridItemRow{
  border-bottom: 1px solid #E5E5E5;
}
/************************************/
.agendaPointLink{
  background-color: white;
  border: 0px;
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
}
.agendaPointLink:hover{
  cursor: pointer;
  text-decoration: none;
}

/**************************************/
.errorIcon{
  color: red;
  font-size: xx-large;
}
